function App() {
  return (
    <div className="w-full h-full fixed flex justify-center items-center flex-col">
      <h1 className='text-center text-6xl'>Work in progress!</h1>
      <footer className="text-center mt-10 animate-pulse">By Simun Posavac</footer>
    </div>
  );
}

export default App;
